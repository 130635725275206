import React, { useRef, useEffect, useState } from 'react';
import { CSVBoxButton } from '@csvbox/react';
import { LocalStorage } from '../scripts/LocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from './FormComponents/Buttons';
import { useNavigate } from 'react-router-dom';
import APP_CONSTANTS from '../scripts/constants';

interface DataImportProps
{
    licenseKey: string;
    dynamicColumns?: any[];
    eventId?: string | number | null;
    targetListId?: string | number | null;
    options?: any;
    btnType?: 'primary' | 'secondary';
    name?: string;
    startIcon?: React.ReactNode;
    iconButton?: boolean;
}

const DataImport = ({
    licenseKey,
    dynamicColumns,
    eventId = null,
    targetListId = null,
    options = null,
    btnType = 'secondary',
    name = 'Import',
    startIcon = <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />,
    iconButton = false 
}: DataImportProps): React.JSX.Element => 
{
    const userMe = LocalStorage.get('@UserMe');
    const org = LocalStorage.get('@Org');

    const user = {
        user_id: userMe.id,
        orgId: org.id
    };

    if (eventId) 
    {
        user['eventId'] = eventId;
    }

    if (targetListId)
    {
        user['targetListId'] = targetListId;
    }

    const csvBoxButtonRef = useRef(null);

    const [triggerLaunch, setTriggerLaunch] = useState(false);
    const [currentLicenseKey, setCurrentLicenseKey] = useState(licenseKey);
    const [keyForRemount, setKeyForRemount] = useState(0);
    const navigate = useNavigate();

    useEffect((): void => 
    {
        setCurrentLicenseKey(licenseKey);
        csvBoxButtonRef.current = null;
        setKeyForRemount((prevKey): number => 
        { 
            return prevKey + 1;
        });
    }, [licenseKey]);

    useEffect(() : void => 
    {
        if (triggerLaunch && csvBoxButtonRef.current) 
        {
            csvBoxButtonRef.current.openModal();
            setTriggerLaunch(false);
        }
    }, [triggerLaunch]);

    useEffect(() => 
    {
        return (): void => 
        {
            csvBoxButtonRef.current = null;
        };
    }, [licenseKey]);

    return (
        <>
            <CSVBoxButton
                key={keyForRemount}
                ref={(el: CSVBoxButton): void => 
                {
                    csvBoxButtonRef.current = el;
                }}
                environment={{
                    env_name: import.meta.env.VITE_NODE_ENV,  
                    base_url: APP_CONSTANTS.API_DOMAIN,
                    authorized_domain: APP_CONSTANTS.APP_DOMAIN,
                }}
                licenseKey={currentLicenseKey}
                user={user}
                dynamicColumns={dynamicColumns}
                onImport={(result, data): void => 
                {
                    if (result) 
                    {
                        console.log('success');
                        if(targetListId && eventId)
                        {
                            navigate(`/events/${eventId}/target-list/${targetListId}`);
                        }
                        console.log(data.row_success + 'rows uploaded');
                    }
                    else 
                    {
                        console.log('fail');
                    }
                }}
                options={options ? options : ''}
                render={(launch, isLoading): React.JSX.Element => 
                {  
                    return (
                        iconButton ?
                        <CustomButton name="" btnType="secondary" disabled={isLoading} onClick={(): void => 
                            {
                                setTriggerLaunch(true);
                            }}
                            startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-from-bracket']} />}
                        />
                        :
                        <CustomButton 
                            btnType={btnType}
                            name={name}
                            startIcon={startIcon}
                            disabled={isLoading} 
                            onClick={(): void => 
                            {
                                setTriggerLaunch(true);
                            }}
                        />
                    );
                }}
            >
                {name}
            </CSVBoxButton>
        </>
    );
};

export default DataImport;