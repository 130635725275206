/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControlLabel, Typography, createFilterOptions } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../../../scripts/i18n';
import moment from 'moment-timezone';
import { addEvent } from '../../../../redux/events/Events';
import { useDispatch, useSelector } from 'react-redux';
import APP_CONSTANTS from '../../../../scripts/constants';
import { createEvent, createEventRegistrationToolMap, getMapEvents } from '../../../../scripts/apis/events';
import eventBus from '../../../../scripts/event-bus';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEventCategory from '../../../../components/Events/EventCategory/CreateEventCategory';
import { getAllEventCategories } from '../../../../scripts/apis/eventCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutocompleteComponent, AutocompleteComponentWithFilterOption, FormControlComponent, FormLabelComponent, RadioGroupComponent, SelectComponent, TimezoneSelectComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import { CustomSwitch } from '../../../../common/StyledComponents/Switch.styled';
import { getFormattedCurrentTimezone, uniqueTimezoneArr } from '../../../../components/Events/timezoneGenerateFunction';
import { EventCategory } from '../../../../pages/Events/interfaces';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../../../pages/Settings/CustomField/CustomTypeComponent';
import { CustomFieldLocations, CustomFieldType, customFieldCountByLocation, customFieldsByLocation } from '../../../../pages/Settings/CustomField/CustomField.helper';
import { ICreateEventProps, IEventReduxValues, IEventsDispatch } from '../../../../components/Events/interfaces/create-events_interface';
import { eventLocationOptions, eventTypeOptions } from '../../../../contexts/EventsPageContext';
import CustomSpinner from '../../../../common/CustomSpinner';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { MapEvent } from '../../../../pages/Events/interfaces/event-registration-tools_interface';
import { getRegistrationForms, registrationFormsCount } from '../../../../scripts/apis/registrationForm';
import { RegistrationForm, RegistrationFormType } from '../../../Registration/interfaces/registration-form_interface';
import { EventLocationType, EventRegistrationTypes, EventStatus, EventTypes } from '../../enum';
import { getIntegrationsData } from '../../../../scripts/apis/integration';
import { IntegrationTypes } from '../../../Settings/enum/integrations.enum';
import { Integrations } from '../../../Settings/interface/integration_interface';
import { combineDateTime, transformTitleToLink } from '../../../../scripts/helpers';
import toast from 'react-hot-toast';
import DateTimePicker from '../../../../common/FormComponents/DateTimePicker';

import './styles.scss';
import { UserContext } from '../../../../contexts/UserContext';
import { UserViewType } from '../../../Settings/enum/users.enum';
import CustomTooltip from '../../../../common/Tooltip';

const CreateEventPage = (props: ICreateEventProps): React.JSX.Element => 
{

    const {
        userDetails,
        orgDetails
    } = useContext(UserContext);

    const eventData = props.eventData;
    const filter = createFilterOptions();
    const [eventCategoryDetails, setEventCategoryDetails] = useState<EventCategory[]>([]);
    const [spinner, setSpinner] = useState(false);
    const [customFormState, setCustomFormState] = useState({});
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [mapEvents, setMapEvents] = useState<MapEvent[]>([]);
    const [registrationFormData, setRegistrationFormData] = useState<RegistrationForm[]>([]);
    const [isLumaIntegrationAvailable, setIsLumaIntegrationAvailable] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentTimezone =  getFormattedCurrentTimezone();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    // const orgLink = LocalStorage.get('@Org')?.link;

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const eventMapOptionLabel = mapEvents?.length > 0 && mapEvents?.reduce((acc, event) =>
    {
        acc[event.id] = event.name;
        return acc;
    }, {});

    const fetchEventsCustomFieldData = async (): Promise<void> => 
    {
        try 
        {
            setCustomFieldsSpinner(true);
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.EVENT);
            if (cfcount)
            {
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount, 0, CustomFieldLocations.EVENT);
                    if (customFields)
                    {
                        setCustomFields(customFields);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
    };

    const handleTimeZoneChange = (event: React.SyntheticEvent, newValue: string): void => 
    {
        formik.setFieldValue('timezone', newValue);

        if (newValue) 
        {
            const timezoneForSelectedValue = _.find(uniqueTimezoneArr, function (item: { name: string; value: string }): boolean
            {
                return item.name === newValue;
            }).value;

            dispatch(addEvent({
                timezoneDisplayName: newValue
            }));

            dispatch(addEvent({
                timeZone: timezoneForSelectedValue ? timezoneForSelectedValue : ''
            }));
        }
    };

    const handleEventType = (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void => 
    {
        formik.setFieldValue('type', event.target.value);
        dispatch(addEvent({
            type: event.target.value
        }));
    };

    const handleEventLocation = (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void => 
    {
        formik.setFieldValue('locationType', event.target.value);
        dispatch(addEvent({
            locationType: event.target.value
        }));
    };

    const handleDrawerClose = (): void => 
    {
        if (userDetails?.viewType === UserViewType.MINIMAL)
        {
            return navigate('/events?eventPhase=2');
        }
        navigate('/events');
    };

    const handleEventCategoryChange = (event: React.SyntheticEvent, newValue: string): void => 
    {
        if (newValue && newValue.includes('Add :')) 
        {
            const eventCategoryName = newValue.split('Add : ')[1];

            setTimeout((): void => 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                    componentHeight: '320px',
                    componentWidth: '600px',
                    iconHeading: 'grid-2-plus',
                    component: <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={eventCategoryName} />,
                });

                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                    open: true
                });
            });
        }
        else 
        {
            formik.setFieldValue('eventCategoryId', newValue);

            const selectedEventCategory = _.filter(eventCategoryDetails, function (group: EventCategory): boolean
            {
                return newValue === group.name;
            })[0];

            dispatch(addEvent({
                eventCategory: newValue ? newValue : ''
            }));

            dispatch(addEvent({
                eventCategoryId: selectedEventCategory ? selectedEventCategory.id : ''
            }));
        }
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = event.target.value;
        const newLink = transformTitleToLink(value);
        formik.setFieldValue('title', value);
        formik.setFieldValue('link', newLink);
        dispatch(addEvent({
            title: value,
            link: newLink,
        }));
    };

    const handleInputChange = (event, id): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            };
        });
    };

    const handleDropDownInputChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            };
        });
    };

    const handleBoxChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            };
        });
    };

    const handleCheckboxChange = (item, data): void => 
    {
        if (customFormState[data.label + ' ' + data.id])
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                };
            });
        }
        else
        {
            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                };
            });
        }

    };

    const handleEditEventCategory = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
            componentHeight: '320px',
            componentWidth: '600px',
            iconHeading: 'grid-2-plus',
            component: <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={formik.values.eventCategoryId} isEdit eventCategoryId={eventReduxData?.eventCategoryId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
            open: true
        });
    };

    const handleMaximumRegistrationChange = (event) =>
    {
        const value = event.target.value;
        formik.setFieldValue('maximumRegistration', value);
        dispatch(addEvent({
            maximumRegistration: value
        }));
    };

    const handleFormChange = (event: React.SyntheticEvent, newValue: string): void => 
    {
        formik.setFieldValue('registrationFormId', newValue);
        const selectedForm = _.filter(registrationFormData, function (form: RegistrationForm): boolean
        {
            return newValue === form.name;
        })[0];

        dispatch(addEvent({
            registrationForm: newValue ? newValue : ''
        }));

        dispatch(addEvent({
            registrationFormId: selectedForm ? selectedForm?.id : ''
        }));
    };

    const categoryFilterOptions = (options: any, params: any): any[] => 
    {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && !options.includes(params.inputValue))
        {
            filtered.push('Add : ' + params.inputValue);
        }

        return filtered;
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required')
            .max(100, 'Event Title length exceeded'),
        link: Yup.string().matches(/^[a-zA-Z0-9_-]+$/, 'Enter valid Public URL').test('isValidLink', 'Public URL is required', (value): boolean => 
        {
            if (formik.values.type === 1) 
            {
                if (!value) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                return true;
            }
        }),
        locationType: Yup.number()
            .required('Please select the Event location'),
        type: Yup.number()
            .required('Please select the Event Type'),
        timezone: Yup.string()
            .required('Timezone is required'),
        registrationType: Yup.number(),
        // eventStartDateTime: Yup.string()
        //     .required('Start Date is required'),
        // eventEndDateTime: Yup.string()
        //     .required('End Date is required'),
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required'),
        startTime: Yup.string().required('Start Time is required'),
        endTime: Yup.string().required('End Time is required'),
        status: Yup.number(),
        eventCategoryId: Yup.string()
            .required('Event Category is required'),
        eventMapId: Yup.string().notRequired(),
        // maximumRegistration: Yup.number().test('isMaximumRegistration', 'Enter Maximum Registrations', (value): boolean => 
        //     {
        //         if (formik.values.type === EventTypes.HOST) 
        //         {
        //             if (!value) 
        //             {
        //                 return false;
        //             }
        //             else 
        //             {
        //                 return true;
        //             }
        //         }
        //         else 
        //         {
        //             return true;
        //         }
        //     }),
        registrationFormId: Yup.string().test('isRegistrationForm', 'Registration Form is required', (value): boolean => 
        {
            if (formik.values.type === EventTypes.HOST) 
            {
                if (!value) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                return true;
            }
        }),
    });

    const formik = useFormik({
        // enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            title: '',
            link: '',
            type: EventTypes.HOST,
            registrationType: EventRegistrationTypes.OPEN,
            locationType: EventLocationType.ONSITE,
            timezone: currentTimezone?.name,
            // eventStartDateTime: '',
            // eventEndDateTime: '',
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().add(1, 'days').format('DD/MM/YYYY'),
            startTime: moment().add(10 - (moment().minute() % 10), 'minutes').format('HH:mm'),
            endTime: moment().add(10 - (moment().minute() % 10), 'minutes').format('HH:mm'),
            status: EventStatus.DRAFT,
            eventCategoryId: eventReduxData?.eventCategoryId || '',
            eventMapId: '',
            // maximumRegistration: '',
            closingDate: moment().add(1, 'days').format('DD/MM/YYYY'),
            closingTime: moment().add(10 - (moment().minute() % 10), 'minutes').format('HH:mm'),
            registrationFormId: '',
        },
        onSubmit: (): void => 
        {
            addEventDetails();
        }
    });

    const addEventDetails = async (): Promise<void> => 
    {
        setSpinner(true);

        const eventStartDateTime = combineDateTime(formik.values.startDate, formik.values.startTime);
        const eventEndDateTime = combineDateTime(formik.values.endDate, formik.values.endTime);
        // const closingDateTime = combineDateTime(formik.values.closingDate, formik.values.closingTime);

        if (Number(eventEndDateTime) < Number(eventStartDateTime)) 
        {
            console.log('inside if condition')
            toast.error('End Date Time should be greater than Start Date Time');
            setSpinner(false);
            return;
        }

        if (moment(eventEndDateTime).diff(moment(eventStartDateTime), 'days') > 30) {
            toast.error('Event duration cannot exceed 30 days');
            setSpinner(false);
            return;
        }

        const eventDetails = {
            title: formik.values.title,
            type: formik.values.type,
            locationType: formik.values.locationType,
            timezone: eventReduxData.timeZone,
            registrationType: formik.values.registrationType,
            eventStartDateTime,
            eventEndDateTime,
            status: EventStatus.DRAFT,
            eventCategoryId: String(eventReduxData.eventCategoryId),
            ...(formik.values.type === EventTypes.HOST && {
                 link: formik.values.link,
                //  maximumRegistration: Number(formik.values.maximumRegistration),
                 closingDateTime: eventEndDateTime,
                 registrationFormId: String(eventReduxData.registrationFormId),
             }),
        };

        if (customFormState)
        {
            const newEventDetails = {
                ...eventDetails,
                ['additionalFieldData']: {
                    ...customFormState
                }
            };
            try
            {
                const eventCreated = await createEvent(newEventDetails, csrfTokenData);
                if (eventCreated)
                {
                    if (formik.values?.eventMapId)
                    {
                        const eventMapObj = {
                            type: 5,
                            mapEvent: [
                                {
                                    eventId: eventCreated.id,
                                    integrationMapId: formik.values?.eventMapId
                                }
                            ]
                        };

                        try
                        {
                            await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                        } catch (error)
                        {
                            console.log('Error with mapping the event', error);
                        }
                    }

                    if (!(eventCreated instanceof Error))
                    {
                        dispatch(addEvent({
                            eventStartDate: moment.unix(eventCreated?.eventStartDateTime).format('YYYY-MM-DD'),
                            eventStartTime: moment.unix(eventCreated?.eventStartDateTime).format('HH:mm'),
                            eventEndDate: moment.unix(eventCreated?.eventEndDateTime).format('YYYY-MM-DD'),
                            eventEndTime: moment.unix(eventCreated?.eventEndDateTime).format('HH:mm'),
                            eventStartDateTime: eventCreated.eventStartDateTime,
                            eventEndDateTime: eventCreated.eventEndDateTime,
                            closingDateTime: eventCreated.eventStartDateTime,
                            eventCategoryId: eventCreated.eventCategoryId,
                            shortName: eventCreated.link,
                            type: eventCreated.type,
                            registrationFormId: eventCreated.registrationFormId,
                            maximumRegistration: eventCreated.maximumRegistration,

                        }));

                        setSpinner(false);

                        toast.success('Event Created');

                        navigate('/events/' + Number(eventCreated.id) + '/overview');
                    }
                }
            } catch (error)
            {
                if (error?.message === 'EVENT LINK EXISTS')
                {
                    toast.error(_.startCase('Another event already has this link'));
                }

                console.log("createEvent", error);

            } finally
            {
                setSpinner(false);
            }
        }
        else 
        {
            try
            {
                const eventCreated = await createEvent(eventDetails, csrfTokenData);
                if (eventCreated)
                {
                    if (formik.values?.eventMapId)
                    {
                        const eventMapObj = {
                            type: 5,
                            mapEvent: [
                                {
                                    eventId: eventCreated.id,
                                    integrationMapId: formik.values?.eventMapId
                                }
                            ]
                        };

                        try
                        {
                            await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                        } catch (error)
                        {
                            console.log('Error with mapping the event', error);
                        }
                    }

                    if (!(eventCreated instanceof Error))
                    {
                        dispatch(addEvent({
                            eventStartDate: moment.unix(eventCreated.eventStartDateTime).format('YYYY-MM-DD'),
                            eventStartTime: moment.unix(eventCreated.eventStartDateTime).format('HH:mm'),
                            eventEndDate: moment.unix(eventCreated.eventEndDateTime).format('YYYY-MM-DD'),
                            eventEndTime: moment.unix(eventCreated.eventEndDateTime).format('HH:mm'),
                            eventStartDateTime: eventCreated.eventStartDateTime,
                            eventEndDateTime: eventCreated.eventEndDateTime,
                            closingDateTime: eventCreated.eventStartDateTime,
                            eventCategoryId: eventCreated.eventCategoryId,
                            shortName: eventCreated.link,
                            type: eventCreated.type,
                            registrationFormId: eventCreated.registrationFormId,
                            maximumRegistration: eventCreated.maximumRegistration,
                        }));

                        setSpinner(false);

                        toast.success('Event Created');

                        navigate('/events/' + Number(eventCreated.id) + '/overview');
                    }
                }
            } catch (error)
            {
                console.log(error);
            } finally
            {
                setSpinner(false);
            }
        }


    };

    const fetchMapEvents = async (): Promise<void> =>
    {
        try 
        {
            const mapEventsData = await getMapEvents(5);
            if (mapEventsData)
            {
                setMapEvents(mapEventsData?.mapEvents);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const eventCategoryData = await getAllEventCategories();
            if (eventCategoryData && eventCategoryData?.length > 0) 
            {
                setEventCategoryDetails([...eventCategoryData]);
                formik.setFieldValue('eventCategoryId', eventCategoryData[0]?.name);
                dispatch(addEvent({
                    eventCategoryId: eventCategoryData[0]?.id,
                    eventCategory: eventCategoryData[0]?.name
                }));
            }
        }
        catch (error) 
        {
            console.log(error);
        }

        try 
        {
            const count = await registrationFormsCount();
            const forms = await getRegistrationForms(count, 0, RegistrationFormType.REGISTRATION_FORM);
            setRegistrationFormData([...forms]);
            // formik.setFieldValue('registrationFormId', forms[0]?.name);
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const fetchIntegrations = async (): Promise<void> =>
    {
        try 
        {
            const integrationsData = await getIntegrationsData();
            if(integrationsData)
            {
                const isLumaIntegrated = integrationsData?.some((integration: Integrations): boolean => {
                    return integration?.type === IntegrationTypes.LUMA;
                });
                setIsLumaIntegrationAvailable(isLumaIntegrated);
            }
        } 
        catch (error) 
        {
            console.log('Error fetching integrations', error)
        }
    };

    useEffect((): void => 
    {
        fetchData();
        fetchIntegrations();
        fetchEventsCustomFieldData();
        fetchMapEvents();
        if (eventData?.additionalFieldData)
        {
            setCustomFormState({
                ...eventData?.additionalFieldData
            });
        }

        if (currentTimezone)
        {
            dispatch(addEvent({
                timezoneDisplayName: currentTimezone?.name,
                timeZone: currentTimezone?.value,
            }));
        }
    }, []);

    useEffect(() => {
        formik.setFieldValue('eventCategoryId', eventReduxData?.eventCategory);
    }, [eventReduxData?.eventCategory])

    return (
        <div id="createEventWrapper">
            <div className="header-container">
                <CustomButton name={'Exit'} btnType={'secondary'} onClick={handleDrawerClose} />
                <div className={`header-text ${formik.values.title ? 'header-text-with-title' : ''}`}>{formik.values.title ? formik.values.title : 'Event Name'} </div>
                <CustomButton
                    type="submit"
                    loading={spinner}
                    name={'Create Event'}
                    btnType={'primary'}
                    onClick={formik.handleSubmit}
                />
            </div>
            <div className="create-event-container">
                <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div>
                        {/* Event Name */}
                        <div className="sidebar-container-spacing">
                            <FormLabelComponent label={'Event Name'} required={true} />
                            <FormControlComponent
                                value={formik.values.title}
                                placeholder={'Enter your event name'}
                                type={'text'}
                                onChange={handleTitleChange}
                                isInvalid={formik.errors.title && formik.touched.title}
                            />
                            {/* <div className="title-length-error-cont">
                            {formik.errors.title && formik.touched.title ? <Typography className="error-msg">{formik.errors.title}</Typography> : <div />}
                            <Typography className="title-length">{eventTitleLength}/100</Typography>
                        </div> */}
                        </div>

                        {/* Event Timing & Timezone */}
                        <div className="sidebar-container-spacing">
                            <div className='form-date-container'>
                                <FormLabelComponent label={'Select Date & Time'} required={true} />
                            </div>
                            <div className="event-timing-wrapper">
                                {/* <div>
                                    <MuiDateTimeRangePicker value={[formik.values.eventStartDateTime, formik.values.eventEndDateTime]} onChange={(newValue): void => 
                                    {
                                        dispatch(addEvent({
                                            eventStartDateTime: newValue[0]?.format('MM/DD/YYYY hh:mm A'),
                                            eventEndDateTime: newValue[1]?.format('MM/DD/YYYY hh:mm A'),
                                            timezoneDisplayName: currentTimezone?.name,
                                            timeZone: currentTimezone?.value,
                                            closingDateTime: newValue[1]?.format('MM/DD/YYYY hh:mm A'),
                                        }));
                                        formik.setFieldValue('eventStartDateTime', newValue[0]?.format('MM/DD/YYYY hh:mm A'));
                                        formik.setFieldValue('eventEndDateTime', newValue[1]?.format('MM/DD/YYYY hh:mm A'));
                                        formik.setFieldValue('timezone', currentTimezone?.name);
                                        formik.setFieldValue('closingDateTime', newValue[1]?.format('MM/DD/YYYY hh:mm A'));
                                    }} desktopPaperStyle={{
                                        position: 'relative',
                                        left: '222px'
                                    }} />
                                    <div className="events-error-msg-container">
                                        {formik.touched.eventStartDateTime && formik.errors.eventStartDateTime ? <Typography className="error-msg">{formik.errors.eventStartDateTime}</Typography> : null}
                                        {formik.touched.eventEndDateTime && formik.errors.eventEndDateTime ? <Typography className="error-msg">{formik.errors.eventEndDateTime}</Typography> : null}
                                    </div>
                                </div> */}

                                <div className="event-timing-container">
                                    <div className="start-timing">
                                        <DateTimePicker 
                                            dateValue={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                                            timeValue={formik.values.startTime}
                                            onDateChange={(newValue): void => {
                                                formik.setFieldValue('startDate', newValue);
                                            }}
                                            onTimeChange={(newValue): void => { 
                                                formik.setFieldValue('startTime', newValue);
                                            }}
                                            dateFormat='ddd, MMM D, YYYY'
                                            showYearDropdown
                                        />
                                    </div>
                                    <p>-</p>
                                    <div className="end-timing">
                                        <DateTimePicker 
                                            dateValue={moment(formik.values.endDate, 'DD/MM/YYYY').unix()}
                                            timeValue={formik.values.endTime}
                                            onDateChange={(newValue): void => {
                                                formik.setFieldValue('endDate', newValue);
                                            }}
                                            onTimeChange={(newValue): void => { 
                                                formik.setFieldValue('endTime', newValue);
                                            }}
                                            dateFormat='ddd, MMM D, YYYY'
                                            minDate={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                                            maxDate={moment(formik.values.startDate, 'DD/MM/YYYY').add(30, 'days').unix()}
                                            showYearDropdown
                                        />
                                    </div>
                                </div>
                                {/* <div className="events-error-msg-container">
                                    {formik.touched.startTime && formik.errors.startTime ? <Typography className="error-msg">{formik.errors.startTime}</Typography> : null}
                                    {formik.touched.endTime && formik.errors.endTime ? <Typography className="error-msg">{formik.errors.endTime}</Typography> : null}
                                </div> */}

                                <div className="event-timezone-component">
                                    <div className="w-100">
                                        <TimezoneSelectComponent defaultValue={formik.values?.timezone} value={formik.values?.timezone} optionsArr={uniqueTimezoneArr} onChange={handleTimeZoneChange} placeholder='Select a timezone' />
                                        {formik.errors.timezone && formik.touched.timezone ? <Typography className="error-msg">{formik.errors.timezone}</Typography> : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Event Location Type */}
                        <div className="sidebar-container-spacing ">
                            <FormLabelComponent label={'Event Format'} required={true} />
                            <RadioGroupComponent row options={eventLocationOptions} value={String(formik.values.locationType)} onChange={handleEventLocation} />
                            {formik.errors.locationType && formik.touched.locationType ? <Typography className="error-msg">{formik.errors.locationType}</Typography> : null}
                        </div>

                        {/* Event Type */}
                        <div className="sidebar-container-spacing">
                            <FormLabelComponent label={'Event Type'} required={true} />
                            <RadioGroupComponent row options={eventTypeOptions} value={String(formik.values.type)} onChange={handleEventType} />
                            {formik.errors.type && formik.touched.type ? <Typography className="error-msg">{formik.errors.type}</Typography> : null}
                        </div>

                        {/* Event Category */}
                        <div className="sidebar-container-spacing">
                            <div className="tooltip-box">
                                <FormLabelComponent label={'Event Category'} required={true} />
                                <CustomTooltip
                                    title="Categorize events based on your preference"
                                >
                                    <FontAwesomeIcon
                                        icon={['fal', 'info-circle']}
                                        className="info-icon"
                                    />
                                </CustomTooltip>
                            </div>
                            <div className="event-category-container">
                                <div className='w-100'>
                                    <AutocompleteComponentWithFilterOption 
                                        value={formik.values.eventCategoryId || ''} 
                                        defaultValue={formik.values.eventCategoryId || ''} 
                                        onChange={handleEventCategoryChange} 
                                        filterOptions={categoryFilterOptions} 
                                        keyToShow='name' 
                                        optionsArr={eventCategoryDetails} 
                                        placeholder={eventCategoryDetails.length > 0 ? CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_SELECT : CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_EMPTY_SELECT} 
                                    />
                                </div>
                                {formik.values?.eventCategoryId && <CustomButton btnType='secondary' name='Edit' onClick={handleEditEventCategory} />}
                            </div>
                            {formik.errors.eventCategoryId && formik.touched.eventCategoryId ? <Typography className="error-msg">{formik.errors.eventCategoryId}</Typography> : null}
                        </div>

                        {Number(formik.values.type) === EventTypes.HOST && <div>
                            {/* Public URL */}
                            <div className="sidebar-container-spacing">
                                <div className="tooltip-box">
                                    <FormLabelComponent label={'Public URL'} required />
                                    <CustomTooltip
                                        title="Host your event landing page in this URL"
                                    >
                                        <FontAwesomeIcon
                                            icon={['fal', 'info-circle']}
                                            className="info-icon"
                                        />
                                    </CustomTooltip>
                                </div>
                                <FormControlComponent
                                    type={'text'}
                                    value={formik.values.link}
                                    placeholder='Public URL'
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('link', event.target.value);
                                        // setEventLinkLength(event.target.value.length);
                                        dispatch(addEvent({
                                            link: event.target.value
                                        }));
                                    }}
                                    isInvalid={formik.errors.link && formik.touched.link}
                                />
                                <Typography className="short-link-example">{`https://${orgDetails?.link}.eventhq.com/${formik.values.link?.length > 0 ? formik.values.link : '*'}`}</Typography>
                                {formik.errors.link && formik.touched.link ? <Typography className="error-msg">{formik.errors.link}</Typography> : null}
                            </div>

                            {isLumaIntegrationAvailable && <Box className="sidebar-container-spacing">
                                <FormLabelComponent label={'Event Map'} />
                                <SelectComponent showNone='Select an event from Luma' selectedAsString options={mapEvents} value={formik.values?.eventMapId} onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                {
                                    formik.setFieldValue('eventMapId', event.target.value);
                                }} optionLabels={eventMapOptionLabel} defaultPlaceholder={'Select an event from Luma'} />
                            </Box>}

                            {/* Require Approval */}
                            <div className="sidebar-container-spacing">
                                <div className="approval-container">
                                    <div className="approval-icon-label-container">
                                        <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" />
                                        <FormLabelComponent label={'Requires Approval to attend'} noBottomMargin />
                                    </div>
                                    <FormControlLabel
                                        checked={formik.values.registrationType === 2}
                                        control={<CustomSwitch
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                            {
                                                formik.setFieldValue('registrationType', event.target.checked ? 2 : 1);
                                                dispatch(addEvent({
                                                    registrationType: event.target.checked ? 2 : 1
                                                }));
                                            }}
                                        />}
                                        label={''}
                                    />
                                </div>
                            </div>

                            {/* Maximum Registrations */}
                            {/* <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Maximum Registrations" required />
                                <FormControlComponent
                                    required
                                    type='number'
                                    onChange={handleMaximumRegistrationChange}
                                    onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                    placeholder='5000'
                                    isInvalid={formik.errors.maximumRegistration && formik.touched.maximumRegistration}
                                />
                                {formik.errors.maximumRegistration && formik.touched.maximumRegistration ? (
                                    <Typography className="error-msg">{formik.errors.maximumRegistration}</Typography>
                                ) : null}
                            </div> */}

                            {/* Registration Closing */}
                            {/* <Box className="sidebar-container-spacing">
                                <FormLabelComponent label='Registration Closing' required />
                                <div className="closing-date-time">
                                    <DateTimePicker 
                                        dateValue={moment(formik.values.closingDate, 'DD/MM/YYYY').unix()}
                                        timeValue={formik.values.closingTime}
                                        onDateChange={(newValue): void => {
                                            formik.setFieldValue('closingDate', newValue);
                                        }}
                                        onTimeChange={(newValue): void => { 
                                            formik.setFieldValue('closingTime', newValue);
                                        }}
                                        dateFormat='ddd, MMM D, YYYY'
                                    />
                                </div>
                                {formik.errors.closingTime && formik.touched.closingTime ? <Typography className="error-msg">{formik.errors.closingTime}</Typography> : null}
                            </Box> */}

                            {/* Registration Form */}
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label={'Registration Form'} required={true} />
                                <div className="event-category-container">
                                    <div className='w-100'>
                                        <AutocompleteComponent value={formik.values.registrationFormId || ''} defaultValue={formik.values.registrationFormId || ''} onChange={handleFormChange}  keyToShow='name' optionsArr={registrationFormData} placeholder='Select Event Form' />
                                    </div>
                                </div>
                                {formik.errors.registrationFormId && formik.touched.registrationFormId ? <Typography className="error-msg">{formik.errors.registrationFormId}</Typography> : null}
                            </div>
                        </div>}

                        {/* Luma event map */}
                        {props?.isLumaIntegrated && <div className="sidebar-container-spacing">
                            <FormLabelComponent label={'Event Map'} />
                            <SelectComponent selectedAsString options={mapEvents} value={formik.values?.eventMapId} onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                            {
                                formik.setFieldValue('eventMapId', event.target.value);
                            }} optionLabels={eventMapOptionLabel} defaultPlaceholder={'Select an event from Luma'} />
                        </div>}

                        {/* Custom fields */}
                        {customFields.length > 0 &&
                            customFieldsSpinner ? <CustomSpinner height={'100%'} /> :
                            <div className="custom-field-container">
                                {customFields.map((item): React.ReactElement => 
                                {
                                    if (item.type === CustomFieldType.NUMBER) 
                                    {
                                        return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                                    }
                                    else if (item.type === CustomFieldType.SINGLE_LINE) 
                                    {
                                        return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                                    }
                                    else if (item.type === CustomFieldType.MULTI_LINE_TEXT) 
                                    {
                                        return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item.name + ' ' + item.id], null);
                                    }
                                    else if (item.type === CustomFieldType.CHECKBOXES) 
                                    {
                                        return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item.label + ' ' + item.id], null);
                                    }
                                    else if (item.type === CustomFieldType.DROPDOWN) 
                                    {
                                        return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item.label + ' ' + item.id], null);
                                    }
                                    else if (item.type === CustomFieldType.RADIO) 
                                    {
                                        return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item.label + ' ' + item.id], null);
                                    }
                                    else if (item.type === CustomFieldType.DATE) 
                                    {
                                        return renderDateComponent(item, handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                                    }
                                })
                                }
                            </div>
                        }
                    </div>
                </Form >
            </div>
        </div>
    );
};


export default CreateEventPage