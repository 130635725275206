import React, { useEffect, useState } from 'react';
import { EventCheckInNotifications } from '../../../../../interfaces/event-check-in-notifications.interface';
import { buildInitialsForPicture, getEncodedParams } from '../../../../../../../scripts/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import { Avatar } from '@mui/material';
import { Stack } from 'react-bootstrap';
import MuiChip from '../../../../../../../common/FormComponents/MuiChip';
import TanstackTable from '../../../../../../../common/TanstackTable/TanstackTable';
import TableEmptyComponent from '../../../../../../../common/TableEmptyComponent';
import { useTablePagination } from '../../../../../../../contexts/TablePaginationContext';
import './styles.scss';
import { User } from '../../../../../../../interfaces/settings/user_interface';
import { getUsers, userCount } from '../../../../../../../scripts/apis/users';
import { CONTENT } from '../../../../../../../scripts/i18n';
import { FormLabelComponent } from '../../../../../../../common/FormComponents/ReusableFormComponents';
import TanstackToolbarWithServerFilter from '../../../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../../../../../common/FormComponents/Buttons';

interface DeliveryNotificationProps 
{
    eventId?: string | number;
    checkInNotifications: EventCheckInNotifications | undefined;
    users: User[];
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    userIds: string[];
    setUserIds: React.Dispatch<React.SetStateAction<string[]>>;
    onTriggerButtonClick?: () => void;
    viewMode?: 'tabs' | 'stepper';
}

const DeliveryNotification: React.FC<DeliveryNotificationProps> = ({
    eventId,
    checkInNotifications,
    users,
    setUsers,
    userIds,
    setUserIds,
    onTriggerButtonClick,
    viewMode
}) => 
{
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [usersCount, setUsersCount] = useState<number>(0);    
    
    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();


    const columnHelper = createColumnHelper<User>();

    const userAccessOptions = {
        1: {
            name: CONTENT.SETTINGS_PAGE.USERS.USER_ROLE[1],
            className: 'green',
        },
        2: {
            name: CONTENT.SETTINGS_PAGE.USERS.USER_ROLE[2],
            className: 'blue',
        },
        3: {
            name: CONTENT.SETTINGS_PAGE.USERS.USER_ROLE[3],
            className: 'orange',
        },
    };

    const userFilterColumns = [
        {
            header: 'Name',
            id: 'firstName',
            meta: {
                type: 'string',
            },
        },
        {
            header: 'Last Name',
            id: 'lastName',
            meta: {
                type: 'string',
            },
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string',
            },
        },
        {
            header: 'Status',
            id: 'status',
            meta: {
                type: 'string',
            }
        }
    ];
    const userNotificationColoumns = [
        columnHelper.accessor((row) => row?.additionalInfo?.image, {
            cell: ({ getValue, row }) => {
                const profilePicture = row.original?.additionalInfo && row.original?.additionalInfo.image ? row.original?.additionalInfo.image : '';
                return (profilePicture !== '' ? <Avatar sx={{
                    width: '32px', height: '32px', borderRadius: '20px' 
                }} src={profilePicture}></Avatar> : <Avatar className="empty-user-avatar">
                    <p>{buildInitialsForPicture(row.original.email, row.original?.firstName, row.original?.lastName)}</p>
                </Avatar>)
            },
            header: '',
            id: 'photo',
            size: 100,
            enableSorting: false,
        }),
        columnHelper.accessor('firstName', {
            cell: ({ row, getValue }) => (
                <Stack>
                    <p className="bolded-cellContent">{row.original.firstName ? row.original.firstName : '-'} {row.original.lastName ? row.original.lastName : ''}</p>
                    {<p className='cellContent'>{row.original.email}</p>}
                </Stack>
            ),
            header: 'Name',
            size: 180,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName',
        }),
        columnHelper.accessor('role', {
          cell: ({row, getValue}) => {
              const chipLabel = userAccessOptions[row.original.role as keyof typeof userAccessOptions]['name'];
              const userOptionsChipColor = userAccessOptions[row.original.role as keyof typeof userAccessOptions]['className'];
              return (
                  <p className="users-role-chip-container">
                      <MuiChip circleIcon label={chipLabel} chipColor={userOptionsChipColor as 'green' | 'orange' | 'grey'} />
                  </p>
              );
          },
          header: 'Status',
          id: 'role',
          sortingFn: (a, b) => { 
              return a.original?.role - b.original?.role;
          }
      }),

    ];

    const userToolBarTabs = [
        {
            tabName: 'All',
            count: usersCount,
            navigation: () => {},
            selectedTab: true,
        },
    ];

    const [allColumns, setAllColumns] = useState(userNotificationColoumns);

    const handleApplyFilters = 
    (
        filters: { selectedColumn: string; inputValue: string }[]
    ): void => 
    {
        filters.forEach((filter) => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const fetchCount = async () => 
    {
        try {
            const encodedParams = getEncodedParams(currentUrl);
            if(checkInNotifications)
            {
                if(checkInNotifications?.userIds && checkInNotifications.userIds.length > 0)
                {
                    const userIds = checkInNotifications.userIds;
                    encodedParams.push(['userIdsStr', encodeURIComponent(userIds.join(','))]);
                    const count = await userCount(encodedParams);
                    if (count) 
                    {
                        setUsersCount(count);
                        setIsEmpty(count == 0 && encodedParams.toString().length === 0);
                    }

                }
            }
            else 
            {
                const count = await userCount(encodedParams);
                if (count) 
                {
                    setUsersCount(count);
                    setIsEmpty(count == 0 && encodedParams.toString().length === 0);
                }
            }
        } catch (error) {
            console.error('Fetching data failed:', error);
        }
    };

    const fetchData = async () => 
    {
        try 
        {
            setShowSpinner(true);
            const encodedParams = getEncodedParams(currentUrl);
            if(checkInNotifications && viewMode === 'tabs')
            {
                if(checkInNotifications?.userIds && checkInNotifications.userIds.length > 0)
                {
                    const userIds = checkInNotifications.userIds;
                    encodedParams.push(['userIdsStr', encodeURIComponent(userIds.join(','))]);
                    const data = await getUsers(pageSize, currentPage - 1,encodedParams);
                    setUsers(data);
                }
            }
            else
            {
                const data = await getUsers(pageSize, currentPage - 1, encodedParams);
                setUsers(data);
            }

        }
        catch (error) 
        {
            console.error('Fetching data failed:', error);
        }
        finally 
        {
            setShowSpinner(false);
            setRefresh(false);
        } 
    };

    useEffect(() => 
    {
        fetchCount();
        fetchData();
    }, [currentUrl, pageSize, currentPage]);

     useEffect((): void => 
    {
        if(refresh) 
        {
            fetchCount();
            fetchData();
        }
    }, [refresh]);

    useEffect(() => 
    {
        setUserIds(checkInNotifications?.userIds ? checkInNotifications.userIds : []);
    }, [checkInNotifications]);


    return (
        <div id='DeliveryNotification'>

            {isEmpty ? (
                <TableEmptyComponent
                    emptyImg={''}
                    infoText={'No User Members Found'}
                    subInfoText={'Add some users to your organisation'}
                />
            ) : (
                <>
                    <FormLabelComponent label={'Notification To'} required={true} />
                    <div className='table-container-with-toolbar'>
                        <div className='table-toolbar'>
                            <TanstackToolbarWithServerFilter
                                columns={allColumns}
                                setColumns={setAllColumns}
                                tabs={userToolBarTabs}
                                setCurrentUrl={setCurrentUrl}
                                filterColumns={userFilterColumns}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                rightCornerButton={checkInNotifications ? <CustomButton
                                    btnType="secondary"
                                    name="Add Notification To"
                                    onClick={() => onTriggerButtonClick && onTriggerButtonClick()}
                                /> : null}
                            />
                        </div>
                        <div className={checkInNotifications && viewMode == 'tabs' ? 'update-table-container-wrapper' : 'table-container-wrapper'}>
                            <TanstackTable
                                checkboxSelection={true}
                                selectedRows={userIds}
                                setSelectedRows={setUserIds}
                                initialColumns={allColumns}
                                data={users}
                                rowCount={usersCount}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                showSpinner={showSpinner}
                                showNoRowsOverlay={usersCount == 0}
                                rightPinnedColumns={['actions']}
                                // height={checkInNotifications ? 'calc(100vh - 310px)' : 'calc(100vh - 360px)'}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DeliveryNotification;