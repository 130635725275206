import React, { useEffect, useState } from 'react';
import {
    getAllEventRegistrants,
    getEventRegistrantCount,
} from '../../../../../../../scripts/apis/eventRegistrants';
import {EventCheckInNotificationsSource } from '../../../../../enum/event-check-in-notifications.enum';
import { EventTargetList } from '../../../../../interfaces/event-target-list-interface';
import { EventTicket } from '../../../../../interfaces';
import { EventCheckInNotifications } from '../../../../../interfaces/event-check-in-notifications.interface';
import { buildInitialsForPicture, getEncodedParams } from '../../../../../../../scripts/helpers';
import { EventRegistrant } from '../../../../../interfaces/event-registrant_interface';
import { createColumnHelper } from '@tanstack/react-table';
import { Avatar } from '@mui/material';
import { Stack } from 'react-bootstrap';
import CustomTooltip from '../../../../../../../common/Tooltip';
import MuiChip from '../../../../../../../common/FormComponents/MuiChip';
import TanstackToolbarWithServerFilter from '../../../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import TanstackTable from '../../../../../../../common/TanstackTable/TanstackTable';
import TableEmptyComponent from '../../../../../../../common/TableEmptyComponent';
import { useTablePagination } from '../../../../../../../contexts/TablePaginationContext';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import DeletePopup from '../../../../../../../common/DeletePopup';
import { CustomButton } from '../../../../../../../common/FormComponents/Buttons';
import { ICP } from '../../../../../../Settings/interface/icp_interface';
import { BuyerPersona } from '../../../../../../Settings/interface/buyer-persona_interface';

interface TriggerNotificationProps 
{
    eventId: string | number;
    notificationSource?: EventCheckInNotificationsSource;
    checkInNotifications?: EventCheckInNotifications;
    targetListData?: EventTargetList[];
    guestTypeData?: any[];
    ticketsData?: EventTicket[];
    icpData?: ICP[];
    bpData?: BuyerPersona[];
    registrants: EventRegistrant[];
    setRegistrants: React.Dispatch<React.SetStateAction<EventRegistrant[]>>;
    onTriggerButtonClick?: () => void;
    viewMode: string;
}

const TriggerNotification: React.FC<TriggerNotificationProps> = ({
    eventId,
    checkInNotifications,
    notificationSource,
    targetListData,
    guestTypeData,
    ticketsData,
    icpData,
    bpData,
    registrants,
    onTriggerButtonClick,
    setRegistrants,
    viewMode,
}) => 
{
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [registrantCount, setRegistrantCount] = useState<number>(0);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const [paginationData, setPaginationData] = useState<EventRegistrant[]>([]);
    
    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();


  const handleDeleteClick = () => 
    {
        const updatedRegistrants = registrants.filter(
          (registrant) => !selectedRowIds.includes(registrant.id)
        );
        setRegistrants(updatedRegistrants);
        setSelectedRowIds([]);
        setShowDeletePopup(false);
        setRefresh(true);
    };
  
    const handleCancelClick = () =>
    {
      setSelectedRowIds([]);
    };

    const columnHelper = createColumnHelper<EventRegistrant>();

    const registrantFilterColumns = [
        {
            header: 'Name',
            id: 'firstName',
            meta: {
                type: 'string',
            },
        },
        {
            header: 'Last Name',
            id: 'lastName',
            meta: {
                type: 'string',
            },
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string',
            },
        },
    ];
    const registrantNotificationColumns = [
        columnHelper.accessor((row) => row?.clearbitData?.companyLogo, {
            cell: ({ getValue, row }) => {
                const clearbitFName = row?.original?.clearbitData?.givenName;
                const clearbitLName = row?.original?.clearbitData?.familyName;
                const name =
                    clearbitFName && clearbitLName
                        ? buildInitialsForPicture('', clearbitFName, clearbitLName)
                        : row?.original?.firstName
                        ? buildInitialsForPicture('', row?.original?.firstName, '')
                        : buildInitialsForPicture('', row?.original?.firstName, '');

                return (
                    <Avatar
                        className='logo-avatar-img'
                        src={
                            row?.original?.clearbitData && row?.original?.clearbitData?.companyLogo
                                ? row?.original?.clearbitData?.companyLogo
                                : ''
                        }
                    >
                        {!row?.original?.clearbitData?.companyLogo ? name : ''}
                    </Avatar>
                );
            },
            header: '',
            id: 'companyLogo',
            size: 100,
            enableSorting: false,
        }),
        columnHelper.accessor('firstName', {
            cell: ({ row, getValue }) => (
                <Stack>
                    <p className="bolded-cellContent">{row.original?.firstName ? row.original.firstName : '-'} {row.original?.lastName ? row.original.lastName : ''}</p>
                    {<p className='cellContent'>{row.original?.email}</p>}
                </Stack>
            ),
            header: 'Name',
            size: 180,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName',
        }),
        columnHelper.accessor('icp', {
            cell: (row: any) => {
                const icpChipColor = 'blue';

                return (
                    <div>
                        <CustomTooltip
                            title={
                                row?.getValue()?.length > 0 &&
                                row
                                    ?.getValue()
                                    ?.map((value: string) => value)
                                    .join(', ')
                            }
                        >
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className='table-chip-cell'>
                                            <MuiChip
                                                circleIcon
                                                chipColor={icpChipColor}
                                                label={row?.getValue()[0]}
                                            />
                                            <p className='table-chip-count'>
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip
                                            circleIcon
                                            chipColor={icpChipColor}
                                            label={row?.getValue()[0]}
                                        />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                        {/* )} */}
                    </div>
                );
            },
            header: 'ICP',
            enableSorting: false,
            id: 'icp',
        }),
        columnHelper.accessor('buyerPersona', {
            cell: (row: any) => {
                const icpChipColor = 'red';

                return (
                    <div>
                        <CustomTooltip
                            title={
                                row?.getValue()?.length > 0 &&
                                row
                                    ?.getValue()
                                    ?.map((value: string) => value)
                                    .join(', ')
                            }
                        >
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className='table-chip-cell'>
                                            <MuiChip
                                                circleIcon
                                                chipColor={icpChipColor}
                                                label={row?.getValue()[0]}
                                            />
                                            <p className='table-chip-count'>
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip
                                            circleIcon
                                            chipColor={icpChipColor}
                                            label={row?.getValue()[0]}
                                        />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                    </div>
                );
            },
            header: 'Buyer Persona',
            enableSorting: false,
            id: 'buyerPersona',
        }),
    ];

    const registrantToolBarTabs = [
        {
            tabName: 'All',
            count: registrantCount,
            navigation: () => {},
            selectedTab: true,
        },
    ];

    const [allColumns, setAllColumns] = useState(registrantNotificationColumns);

    const fetchCount = async () => 
    {
        try {
            const encodedParams = getEncodedParams(currentUrl);

             if(viewMode === 'tabs' && registrants && registrants?.length > 0)
            {
                    const registrantIds = registrants.map((registrant) => registrant.id);
                    encodedParams.push(['registrantIdsStr', encodeURIComponent(registrantIds.join(','))]);
                    const count = await getEventRegistrantCount(Number(eventId), encodedParams);
                    if (count) 
                    {
                        setRegistrantCount(count);
                        setIsEmpty(count == 0 && encodedParams.toString().length === 0);
                    }
            }
            else if(viewMode === 'tabs' && checkInNotifications)
            {
                if(checkInNotifications.registrantIds && checkInNotifications.registrantIds.length > 0){
                    const registrantIds = checkInNotifications.registrantIds;
                    encodedParams.push(['registrantIdsStr', encodeURIComponent(registrantIds.join(','))]);
                    const count = await getEventRegistrantCount(Number(eventId), encodedParams);
                    if (count) 
                    {
                      setRegistrantCount(count);
                      setIsEmpty(false);
                    }
                }
                else
                {
                    setRegistrantCount(0);
                    setIsEmpty(false);
                }
            }
            else if (notificationSource == EventCheckInNotificationsSource.TAREGET_LIST) 
            {

                if(targetListData && targetListData?.length > 0)
                {
                    const audienceIds = Array.from(new Set(targetListData.flatMap((targetList) => targetList.audienceIds)));
                    encodedParams.push(['audienceIdsStr', encodeURIComponent(audienceIds.join(','))]);
                }

                const count = await getEventRegistrantCount(Number(eventId), encodedParams);
                if (count) 
                {
                  setRegistrantCount(count);
                  setIsEmpty(count == 0 && encodedParams.toString().length === 0);
                }
            }
            else 
            {

                if(guestTypeData && guestTypeData?.length > 0)
                {
                    const guestTypeArr = guestTypeData.map((guestType) => guestType.id);
                    encodedParams.push(['guestTypeArrStr', encodeURIComponent(guestTypeArr.join(','))]);
                }

                if(ticketsData && ticketsData?.length > 0)
                {
                    const ticketIds = Array.from(new Set(ticketsData.flatMap((ticket) => ticket.id)));
                    encodedParams.push(['ticketIdsArr', encodeURIComponent(ticketIds.join(','))]);
                }

                if(icpData && icpData?.length > 0)
                {
                    const icp = Array.from(new Set(icpData.flatMap((icp) => icp.name)));
                    encodedParams.push(['icps', encodeURIComponent(icp.join(','))]);
                }

                if(bpData && bpData?.length > 0)
                {
                    const bp = Array.from(new Set(bpData.flatMap((bp) => bp.name)));
                    encodedParams.push(['bps', encodeURIComponent(bp.join(','))]);
                }

                const count = await getEventRegistrantCount(Number(eventId), encodedParams);
                if (count) 
                {
                  setRegistrantCount(count);
                  setIsEmpty(count == 0 && encodedParams.toString().length === 0);
                }
            }
        } catch (error) {
            console.error('Fetching data failed:', error);
        }
    };

    const fetchData = async () => 
    {
        try 
        {
            setShowSpinner(true);
            const encodedParams = getEncodedParams(currentUrl);

            if(viewMode === 'tabs' && registrants && registrants.length > 0)
            {
                const registrantIds = registrants.map((registrant) => registrant.id);
                encodedParams.push(['registrantIdsStr', encodeURIComponent(registrantIds.join(','))]);
                const data = await getAllEventRegistrants(Number(eventId), pageSize, currentPage - 1, ...encodedParams);
                setPaginationData(data);
            }
            else if(viewMode === 'tabs' && checkInNotifications && checkInNotifications.registrantIds)
            {
                if(checkInNotifications.registrantIds && checkInNotifications.registrantIds.length > 0)
                {
                    const registrantIds = checkInNotifications.registrantIds;
                    encodedParams.push(['registrantIdsStr', encodeURIComponent(registrantIds.join(','))]);
                    const data = await getAllEventRegistrants(Number(eventId), pageSize, currentPage - 1 , ...encodedParams);
                    setPaginationData(data);
                    if(registrants.length === 0)
                    {
                        setRegistrants(data);
                    }
                }
                else
                {
                    setPaginationData([]);
                }

            }
            else if(notificationSource == EventCheckInNotificationsSource.TAREGET_LIST) 
            {
                if(targetListData && targetListData?.length > 0)
                {
                    const audienceIds = Array.from(new Set(targetListData.flatMap((targetList) => targetList.audienceIds)));
                    encodedParams.push(['audienceIdsStr', encodeURIComponent(audienceIds.join(','))]);
                }

                const data = await getAllEventRegistrants(
                    Number(eventId),
                    pageSize,
                    currentPage - 1,
                    ...encodedParams
                );
                const allRegistrants = await getAllEventRegistrants(
                    Number(eventId),
                    registrantCount,
                    0,
                    ...encodedParams
                );
                setRegistrants(allRegistrants);
                setPaginationData(data);
            } 
            else if(notificationSource == EventCheckInNotificationsSource.REGISTRANTS)
            {
                if(guestTypeData && guestTypeData?.length > 0)
                {
                    const guestTypeArr = guestTypeData.map((guestType) => guestType.id);
                    encodedParams.push(['guestTypeArrStr', encodeURIComponent(guestTypeArr.join(','))]);
                }

                if(ticketsData && ticketsData?.length > 0)
                {
                    const ticketIds = Array.from(new Set(ticketsData.flatMap((ticket) => ticket.id)));
                    encodedParams.push(['ticketIdsArr', encodeURIComponent(ticketIds.join(','))]);
                }

                if(icpData && icpData?.length > 0)
                {
                    const icp = Array.from(new Set(icpData.flatMap((icp) => icp.name)));
                    encodedParams.push(['icps', encodeURIComponent(icp.join(','))]);
                }

                if(bpData && bpData?.length > 0)
                {
                    const bp = Array.from(new Set(bpData.flatMap((bp) => bp.name)));
                    encodedParams.push(['bps', encodeURIComponent(bp.join(','))]);
                }

                const data = await getAllEventRegistrants(
                    Number(eventId),
                    pageSize,
                    currentPage - 1,
                    ...encodedParams
                );
                const allRegistrants = await getAllEventRegistrants(
                    Number(eventId),
                    registrantCount,
                    0,
                    ...encodedParams
                );
                setRegistrants(allRegistrants);
                setPaginationData(data);
            }
        }
        catch (error) 
        {
            console.error('Fetching data failed:', error);
        }
        finally 
        {
            setShowSpinner(false);
            setRefresh(false);
        } 
    };

    const handleApplyFilters = 
    (
        filters: { selectedColumn: string; inputValue: string }[]
    ): void => 
    {
        filters.forEach((filter) => {
            if (filter.selectedColumn === 'source') {
                searchParams.append('sourcesArr', filter.inputValue);
            } else {
                searchParams.append(filter.selectedColumn, filter.inputValue);
            }
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    useEffect(() => 
    {
        fetchCount();
        fetchData();
    }, [currentUrl, pageSize, currentPage]);

     useEffect((): void => 
    {
        if(refresh) 
        {
            fetchCount();
            fetchData();
        }
    }, [refresh]);


    return (
        <div id={checkInNotifications ? 'TriggerUpdateNotification' :'TriggerNotification'}>
            {isEmpty ? (
                <TableEmptyComponent
                    emptyImg={''}
                    infoText={'No Trigger Notifications List'}
                    subInfoText={'No Trigger Registrants found'}
                />
            ) : (
                <>
                    <div className='table-toolbar'>
                        {selectedRowIds.length > 0 ? (
                            <div className='button-row'>
                                <CustomButton
                                    btnType='danger'
                                    name='Delete'
                                    onClick={() => setShowDeletePopup(true)}
                                />
                                <CustomButton
                                    btnType='secondary'
                                    name='Cancel'
                                    onClick={handleCancelClick}
                                />
                                <div className='table-header-text'>
                                    {' '}
                                    {selectedRowIds.length} records selected{' '}
                                </div>
                                
                            </div>
                        ) : (
                            <TanstackToolbarWithServerFilter
                                columns={registrantNotificationColumns}
                                setColumns={setAllColumns}
                                tabs={registrantToolBarTabs}
                                setCurrentUrl={setCurrentUrl}
                                filterColumns={registrantFilterColumns}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                rightCornerButton={checkInNotifications ? <CustomButton 
                                    btnType="secondary"
                                    name="Add Trigger"
                                    onClick={() => onTriggerButtonClick && onTriggerButtonClick()}
                                />: null}
                            />
                        )}
                    </div>

                    <TanstackTable
                        checkboxSelection={true}
                        selectedRows={selectedRowIds}
                        setSelectedRows={setSelectedRowIds}
                        initialColumns={allColumns}
                        data={paginationData}
                        rowCount={registrantCount}
                        pageSize={pageSize}
                        currentPage={currentPage - 1}
                        updateCurrentPage={updateCurrentPage as any}
                        updatePageSize={updatePageSize}
                        showSpinner={showSpinner}
                        showNoRowsOverlay={registrantCount == 0}
                        rightPinnedColumns={['actions']}
                        // height={checkInNotifications ? 'calc(100vh - 145px)' :'calc(100vh - 395px)'}
                    />
                </>
            )}
            {showDeletePopup && (
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={handleDeleteClick}
                    cancelClick={() => setShowDeletePopup(false)}
                    modalContent={`Are you sure you want to delete the selected records?`}
                    modalTitle='Delete Records'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                />
            )}
        </div>
    );
};

export default TriggerNotification;
