import React, { useState } from 'react';
import TriggerNotification from './StepperComponents/TriggerNotification';
import DeliveryNotification from './StepperComponents/DeliveryNotification';
import MessageNotification from './StepperComponents/MessageNotification';
import { useSelector } from 'react-redux';
import { EventCheckInNotifications } from '../../../../interfaces/event-check-in-notifications.interface';
import TablePopupTabs from '../../../../../../common/TablePopup/TablePopupTabs';
import { CloseIconComponent } from '../../../../../../common/FormComponents/ReusableFormComponents';
import eventBus from '../../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../../scripts/constants';
import SelectOptionNotification from './StepperComponents/SelectOptionNotification';
import { EventTicket } from '../../../../interfaces';
import { EventTargetList } from '../../../../interfaces/event-target-list-interface';
import { EventCheckInNotificationsSource } from '../../../../enum/event-check-in-notifications.enum';
import { EventRegistrant } from '../../../../interfaces/event-registrant_interface';
import Stepper, { CheckoutStep } from '../../../../../../common/Stepper';
import { User } from '../../../../../../interfaces/settings/user_interface';
import { createCheckInNotifications, updateCheckInNotifications } from '../../../../../../scripts/apis/eventCheckInNotifications';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import { ICP } from '../../../../../Settings/interface/icp_interface';
import { BuyerPersona } from '../../../../../Settings/interface/buyer-persona_interface';
import toast from 'react-hot-toast';
import NotificationStepper from './NotificationsStepper';

interface AddCheckInNotificationsProps {
  eventId: string | number;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  checkInNotifications: EventCheckInNotifications | undefined;
}

const AddCheckInNotifications: React.FC<AddCheckInNotificationsProps> = ({ eventId, setRefresh, checkInNotifications }): JSX.Element => {
  
  const [notificationSource, setNotificationSource] = useState<EventCheckInNotificationsSource>(EventCheckInNotificationsSource.TAREGET_LIST);
  const [targetListData, setTargetListData] = useState<EventTargetList[]>([]);
  const [guestTypeData, setGuestTypeData] = useState<any[]>([]);
  const [ticketsData, setTicketsData] = useState<EventTicket[]>([]);
  const [icpData, setIcpData] = useState<ICP[]>([]);
  const [bpData, setBpData] = useState<BuyerPersona[]>([]);
  const [viewMode, setViewMode] = useState<'tabs' | 'stepper'>(checkInNotifications ? 'tabs' : 'stepper')

  const [registrants, setRegistrants] = useState<EventRegistrant[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);

  const [messageTitle, setMessageTitle] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');

  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);

  const navigate = useNavigate();

  const csrfTokenData = useSelector((state): string => {
    return state['csrfTokenValue'].value.csrfToken;
  });

  const validateMessage = async () => 
  {
    if (messageTitle === '') {
      toast.error('Please enter a message title');
      return false;
    }
    if (messageContent === '') {
      toast.error('Please enter a message content');
      return false;
    }
    return true;
  } 
  
  const validateOptions = async () => 
  {
    if (notificationSource === EventCheckInNotificationsSource.TAREGET_LIST && targetListData.length === 0) {
      toast.error('Please select at least one target list');
      return false;
    }
    if (notificationSource === EventCheckInNotificationsSource.REGISTRANTS && guestTypeData.length === 0) {
      toast.error('Please select at least one guest type');
      return false;
    }
    return true;
  }

  const validateNotificationTo = async () => 
  {
    if (userIds.length === 0) {
      toast.error('Please select at least one user');
      return false;
    }
    return true;
  }

  const submitCheckInNotification = async () => {
    try {
      const checkInNotification: EventCheckInNotifications = {
        title: messageTitle,
        messageContent: messageContent,
        source: notificationSource,
        registrantIds: registrants.map((registrant) => registrant.id),
        userIds: userIds,
        filters: {
          targetList: targetListData.length > 0 ? targetListData.map((targetList) => targetList.id.toString()) : [],
          guestType: guestTypeData.map((guestType) => guestType.name),
          tickets:  ticketsData.map((ticket) => ticket.id.toString()),
          icp: icpData.map((icp) => icp.id.toString()),
          bp: bpData.map((bp) => bp.id.toString()),
        },
      };

      if(checkInNotifications?.id)
      {
        await updateCheckInNotifications(checkInNotification, eventId, Number(checkInNotifications.id));
      }
      else
      {
        await createCheckInNotifications(checkInNotification, eventId, csrfTokenData);
      }
      navigate(`/events/${eventId}/check-in`);
      handleDrawerClose();
      setRefresh(true);

    } catch (error) {
      console.error('Error submitting Check In Notification:', error);
      throw error;
    }
  };


  const updateCheckInNotification = async () => {
    try {

      if(checkInNotifications)
      {
        const checkInNotification: EventCheckInNotifications = {
          title: messageTitle ? messageTitle : checkInNotifications?.title,
          messageContent: messageContent ? messageContent : checkInNotifications?.messageContent,
          source: notificationSource ? notificationSource : checkInNotifications?.source,
          registrantIds: registrants ? registrants.map((registrant) => registrant.id) : checkInNotifications?.registrantIds,
          userIds: userIds ? userIds : checkInNotifications?.userIds,
          filters: {
            targetList: targetListData.length > 0 ? targetListData.map((targetList) => targetList.id.toString()) : checkInNotifications?.filters?.targetList,
            guestType: guestTypeData.length > 0 ? guestTypeData.map((guestType) => guestType.name) : checkInNotifications?.filters?.guestType,
            tickets:  ticketsData.length > 0 ? ticketsData.map((ticket) => ticket.id.toString()) : checkInNotifications?.filters?.tickets,
            icp: icpData.length > 0 ? icpData.map((icp) => icp.id.toString()) : checkInNotifications?.filters?.icp,
            bp: bpData.length > 0 ? bpData.map((bp) => bp.id.toString()) : checkInNotifications?.filters?.bp,
          },
        };

        await updateCheckInNotifications(checkInNotification, eventId, Number(checkInNotifications.id));
        navigate(`/events/${eventId}/check-in`);
        handleDrawerClose();
        setRefresh(true);
     }
    } catch (error) {
      console.error('Error submitting Check In Notification:', error);
      throw error;
    }
  };
  
  const CHECKOUT_STEPS: CheckoutStep[] = [
    {
      name: "Filters",
      Component:
      <SelectOptionNotification
          eventId={eventId}
          targetListData={targetListData}
          setTargetListData={setTargetListData}
          guestTypeData={guestTypeData}
          setGuestTypeData={setGuestTypeData}
          ticketsData={ticketsData}
          setTicketsData={setTicketsData}
          icpData={icpData}
          setIcpData={setIcpData}
          bpData={bpData}
          setBpData={setBpData}
          notificationSource={notificationSource}
          setNotificationSource={setNotificationSource}
          checkInNotifications={checkInNotifications}
        />
    },
    {
      name: "Trigger",
      Component:
        <TriggerNotification
          eventId={eventId}
          notificationSource={notificationSource}
          targetListData={targetListData}
          guestTypeData={guestTypeData}
          ticketsData={ticketsData}
          icpData={icpData}
          bpData={bpData}
          registrants={registrants}
          setRegistrants={setRegistrants}
          viewMode={viewMode}
        />
      
    },
    {
      name: "Notification To",
      Component: <DeliveryNotification eventId={eventId} checkInNotifications={checkInNotifications} users={users} setUsers={setUsers} userIds={userIds} setUserIds={setUserIds}/>,
      onComplete: async() => { validateNotificationTo();}
    },
    {
      name: "Message",
      Component: <MessageNotification checkInNotifications={checkInNotifications} messageTitle={messageTitle} setMessageTitle={setMessageTitle}  messageContent={messageContent} setMessageContent={setMessageContent} />,
      onComplete: async () => { submitCheckInNotification(); },
    },
  ];

  const tablePopupTabData = [
    {
      title: 'Trigger',
      data: <TriggerNotification onTriggerButtonClick={() => setViewMode('stepper')} eventId={eventId} checkInNotifications={checkInNotifications} registrants={registrants} setRegistrants={setRegistrants} viewMode={viewMode}/>
    },
    {
      title: 'Notification To',
      data: <DeliveryNotification onTriggerButtonClick={() => setViewMode('stepper')} eventId={eventId} checkInNotifications={checkInNotifications} users={users} setUsers={setUsers} userIds={userIds} setUserIds={setUserIds} viewMode={viewMode} />
    },
    {
      title: 'Message',
      data: <MessageNotification checkInNotifications={checkInNotifications} messageTitle={messageTitle} setMessageTitle={setMessageTitle}  messageContent={messageContent} setMessageContent={setMessageContent} />,
    }
  ];

  const handleDrawerClose = (): void => {
    eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
      open: false,
    });
    setNotificationSource(EventCheckInNotificationsSource.TAREGET_LIST);
    setTargetListData([]);
    setGuestTypeData([]);
    setTicketsData([]);
    setIcpData([]);
    setBpData([]);
    setRegistrants([]);
    setUsers([]);
    setUserIds([]);
    setMessageTitle('');
    setMessageContent('');
    setViewMode('stepper');
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
    setIsComplete(false);
  };

  const handleNext = async () => {

    if(currentStep === 1)
    {
        const res = await validateOptions();
        if(!res)
        {
            return ;
        }
    }
    if(currentStep === 3)
    {
        const res = await validateNotificationTo();
        if(!res)
        {
            return ;
        }
    }
    if(currentStep === 4)
    {
        const res = await validateMessage();
        if(!res)
        {
            return ;
        }
    }

    if (currentStep === CHECKOUT_STEPS.length) {
        submitCheckInNotification();
        setIsComplete(true);
    } else {
        setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  return (
    <div id='addCheckInNotification' style={{ paddingTop: '24px' }}>
      <CloseIconComponent onClick={handleDrawerClose} />
      {viewMode === 'tabs' ? (
          <div className='side-drawer-notification'>
           <TablePopupTabs tabData={tablePopupTabData} onPopupClose={() => { }} />

            <div className={`button-tab-container`}> 
              <CustomButton
                  name="Cancel"
                  btnType={"secondary"}
                  onClick={handleDrawerClose}
                  style={{ marginRight: "auto" }}
              />
              <CustomButton
                  name={"Save"}
                  btnType={"primary"}
                  onClick={updateCheckInNotification}
              />
            </div>
          </div>
      ) : (
        <>
        <NotificationStepper
            props={CHECKOUT_STEPS}
            buttonPosition='bottom'
            currentStep={currentStep}
            isComplete={isComplete}
        />
        {!isComplete && (
            <div className={`button-tab-container`}> 
                <CustomButton
                    name="Back"
                    btnType={"secondary"}
                    onClick={handleBack}
                    disabled={currentStep === 1}
                    style={{ marginRight: "auto" }}
                />
                <CustomButton
                    name={currentStep === CHECKOUT_STEPS.length ? "Finish" : "Next"}
                    btnType={"primary"}
                    onClick={handleNext}
                />
            </div>
        )}
      </>
      )}
    </div>
  );
}

export default AddCheckInNotifications;